import { useContext, useEffect, useRef, useState } from "react";
// import * as OBC from "openbim-components";
import * as THREE from 'three'
import * as OBC1 from "@thatopen/components";
// import * as OBCF from '@thatopen/fragments'
import * as OBCF from '@thatopen/components-front'
import * as UIOBC from '@thatopen/ui-obc'
import * as openUI from "@thatopen/ui"
import axios from 'axios';
import CameraControls from "camera-controls";
import { AppStateContext } from "../../Context/contextState";
import GuideBox from "../ModalGuide/ModalGuide.jsx";
import walking from '../../assets/walking.png';
import clear from '../../assets/clear.png';
import angle from '../../assets/angle.png';
import area from '../../assets/area.png';
import edge from '../../assets/edge.png';
import lenght from '../../assets/length.png';
import fullSize from '../../assets/full-size.png';
import reDo from '../../assets/undo.png';
import noFullScreen from '../../assets/fullscreen.png'
import rotation from '../../assets/rotation.png'
import menu from '../../assets/menu.png'
import plansImg from '../../assets/layout.png'
import clipperImg from '../../assets/crop-tool.png'
import mapImg from '../../assets/map.png'
import './Canvas.css'
import * as Icon from "react-bootstrap-icons";

UIOBC.Manager.init()
openUI.Manager.init()

export default function CanvasPublish(props) {

    const {
        setLoading,
    } = useContext(AppStateContext);

    const [downloadProgress, setDownloadProgress] = useState(0);
    const [backgroundBtn, setBackgroundBtn] = useState(true)
    const downloadProgressRef = useRef(0);
    const propertiesRef = useRef(null);
    const [isPropertiesPanel, setIsPropertiesPanel] = useState(false);
    const minimapPanelRef = useRef(null);
    const [isMinimapPanel, setIsMinimapPanel] = useState(false);
    const plansRef = useRef(null);
    const [isPlans, setIsPlans] = useState(false);
    const [measurementMenu, setMeasurementMenu] = useState(false);
    const resizableRef = useRef(null);
    const resizerRef = useRef(null);
    const [size1, setSize1] = useState({ width: 200, height: 200 });
    let map;
    const mapRef = useRef(null)
    const [positionPro, setPositionPro] = useState({ top: 100, left: 10 });
    const draggingPro = useRef(false);
    const offsetPro = useRef({ x: 0, y: 0 });

    const componentsRef = useRef(null)
    const firstload = useRef(true)
    let clipperBolean = useRef(false)
    const fileURL = useRef()
    let controls;
    const canvasRef = useRef(null)
    const canvasMinimap = useRef(null)


    const [filePath, setFilePath] = useState();
    let classifier, culler, cullers, plans, blueFill, blueLine, blueOutline, components, components1, model, casters, clipper, edges;
    let fragmentIfcLoader, world, indexer, fragments, highlighter;

    async function loadIfcAsFragments() {
        setLoading(false)

        await axios({
            // url: 'https://3dbuilding.parisashahbazi.com/uploads/1TA12.ifc', //your url
            url: `${fileURL.current}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            onDownloadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                downloadProgressRef.current = percentage;
                setDownloadProgress(percentage);
                // console.log("downloadProgressBar>>", percentage);

            }
        }).then(async (response) => {
            try {
                const buffer = await response.data.arrayBuffer();
                const data = new Uint8Array(buffer);
                model = await fragmentIfcLoader.load(data);
                // console.log('model>', model)
                model.name = "example";
                world.scene.three.add(model);
                for (const child of model.children) {
                    if (child instanceof THREE.Mesh) {
                        world.meshes.add(child);
                    }
                }
                // edges.styles.create(
                //     "Red lines",
                //     new Set([model]),
                //     world,
                //     blueLine,
                //     blueFill,
                //     blueOutline,
                // );

                await indexer.process(model);
                await plans.generate(model);
                // for (const fragment of model.items) {
                //     culler.add(fragment.mesh);
                // }

                // culler.needsUpdate = true;

                classifier.byModel(model.uuid, model);
                classifier.byEntity(model);

                const modelItems = classifier.find({ models: [model.uuid] });

                const thickItems = classifier.find({
                    entities: ["IFCWALLSTANDARDCASE", "IFCWALL"],
                });

                const thinItems = classifier.find({
                    entities: ["IFCDOOR", "IFCWINDOW", "IFCPLATE", "IFCMEMBER"],
                });

                const grayFill = new THREE.MeshBasicMaterial({ color: "gray", side: 2 });
                const blackLine = new THREE.LineBasicMaterial({ color: "black" });
                const blackOutline = new THREE.MeshBasicMaterial({
                    color: "black",
                    opacity: 0.5,
                    side: 2,
                    transparent: true,
                });

                edges.styles.create(
                    "thick",
                    new Set(),
                    world,
                    blackLine,
                    grayFill,
                    blackOutline,
                );

                for (const fragID in thickItems) {
                    const foundFrag = fragments.list.get(fragID);
                    if (!foundFrag) continue;
                    const { mesh } = foundFrag;
                    edges.styles.list.thick.fragments[fragID] = new Set(thickItems[fragID]);
                    edges.styles.list.thick.meshes.add(mesh);
                }
                edges.styles.create("thin", new Set(), world);

                for (const fragID in thinItems) {
                    const foundFrag = fragments.list.get(fragID);
                    if (!foundFrag) continue;
                    const { mesh } = foundFrag;
                    edges.styles.list.thin.fragments[fragID] = new Set(thinItems[fragID]);
                    edges.styles.list.thin.meshes.add(mesh);
                }

                await edges.update(true);
                const panel = openUI.Component.create(() => {
                    return openUI.html`
                    <bim-panel active label="Plans Tutorial" class="options-menu" id="plansPanel">
                        <bim-panel-section collapsed name="floorPlans" label="Plan list">
                        </bim-panel-section>
                      </bim-panel>
                      `
                })


                plansRef.current.appendChild(panel);
                const minGloss = world.renderer.postproduction.customEffects.minGloss

                const whiteColor = new THREE.Color("white")

                const panelSection = panel.querySelector("bim-panel-section[name='floorPlans']")

                for (const plan of plans.list) {
                    const planButton = openUI.Component.create(() => {
                        return openUI.html`
                             <bim-button checked label="${plan.name}"
                                 @click="${() => {
                                world.renderer.postproduction.customEffects.minGloss = 0.1
                                highlighter.backupColor = whiteColor
                                classifier.setColor(modelItems, whiteColor)
                                world.scene.three.background = whiteColor
                                plans.goTo(plan.id)
                            }}">
                             </bim-button>`
                    })
                    panelSection.append(planButton)
                }

                const defaultBackground = world.scene.three.background

                const exitButton = openUI.Component.create(() => {
                    return openUI.html`
                      <bim-button checked label="Exit"
                          @click="${() => {
                            highlighter.backupColor = null
                            world.renderer.postproduction.customEffects.minGloss = minGloss
                            classifier.resetColor(modelItems)
                            world.scene.three.background = defaultBackground
                            plans.exitPlanView()
                        }}">
                      </bim-button>
                        `
                })

                panelSection.append(exitButton)
            }
            catch {
                props.ToastsShow(true);
                props.ToastsShowBack(false);
                props.ToastsText("Your model has problem");
            }
            finally {
                setDownloadProgress(100);
                setLoading(false);
                setTimeout(() => {
                    props.ToastsShow(false);
                }, 6000);
            }
            document.querySelector(".properties-container").children[0].id = "propertiesPanel";
            // document.querySelector(".minimapPanel-container").children[0].id = "minimapPanel";
        })


    }

    // async function init() {
    //     setLoading(false);
    //     components = new OBC.Components();
    //     componentsRef.current = components

    //     components.scene = new OBC.SimpleScene(components);
    //     // components.renderer = new OBC.SimpleRenderer(components, canvasRef.current);
    //     components.renderer = new OBC.PostproductionRenderer(components, canvasRef.current)

    //     const materialManager = new OBC.MaterialManager(components);
    //     const backgroundColor = new THREE.Color("#878787");
    //     materialManager.setBackgroundColor(backgroundColor);

    //     camera = new OBC.OrthoPerspectiveCamera(components);
    //     // controls = camera.controls

    //     //new version
    //     controls = camera.controls
    //     controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY
    //     //  controls.dollyToCursor=true;
    //     camera.controls.setLookAt(5, 5, 5, 0, 0, 0);
    //     controls.infinityDolly = false
    //     controls.minDistance = 0.001; //dolly
    //     controls.maxDistance = 300; //dolly
    //     controls.mouseButtons.middle = CameraControls.ACTION.TRUCK;
    //     controls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
    //     controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
    //     controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    //     controls.truckSpeed = 5;
    //     controls.dollySpeed = 6
    //     //end new version


    //     components.camera = camera;
    //     console.log('components.camera', components.camera)
    //     componentsRef.current = components
    //     components.init();
    //     shadows = new OBC.ShadowDropper(components);
    //     shadows.shadowExtraScaleFactor = 55;
    //     shadows.darkness = 5;

    //     let keys = {
    //         w: false,
    //         d: false,
    //         a: false,
    //         s: false
    //     };
    //     function onKeyDown(event) {

    //         if (event.key === 'w') {
    //             keys.w = true;
    //         }
    //         if (event.key === 'd') {
    //             keys.d = true;
    //         }
    //         if (event.key === 'a') {
    //             keys.a = true;
    //         }
    //         if (event.key === 's') {
    //             keys.s = true;
    //         }

    //         if (keys.w && keys.d) {
    //             camera.controls.forward(0.5, true)
    //             camera.controls.truck(0.5, 0, true)
    //         }
    //         if (keys.w && keys.a) {
    //             camera.controls.forward(0.5, true)
    //             camera.controls.truck(-0.5, 0, true)
    //         }
    //         if (keys.s && keys.a) {
    //             camera.controls.forward(-0.5, true)
    //             camera.controls.truck(-0.5, 0, true)
    //         }
    //         if (keys.s && keys.d) {
    //             camera.controls.forward(-0.5, true)
    //             camera.controls.truck(0.5, 0, true)
    //         }

    //         switch (event.keyCode) {
    //             case 37:// left
    //                 camera.controls.rotate(+0.1, 0, true)
    //                 break
    //             case 65:
    //                 camera.controls.truck(-0.25, 0, true)
    //                 break;
    //             case 38:
    //             case 87:
    //                 if (event.shiftKey) {
    //                     console.log('shift up')
    //                     camera.controls.forward(1.5, true)
    //                     break;

    //                 }
    //                 console.log('up')
    //                 camera.controls.forward(0.25, true)
    //                 break;
    //             case 39:
    //                 camera.controls.rotate(-0.1, 0, true)
    //                 break
    //             case 68:
    //                 console.log('right')
    //                 camera.controls.truck(0.25, 0, true)
    //                 break;
    //             case 40:
    //             case 83:
    //                 if (event.shiftKey) {
    //                     console.log('shift down')
    //                     camera.controls.forward(-1.5, true)
    //                     break;

    //                 }
    //                 console.log('down')
    //                 camera.controls.forward(-0.25, true)
    //                 break;
    //             case 81:
    //                 camera.controls.truck(0, -0.25, true)
    //                 console.log('q top')
    //                 break;
    //             case 69:
    //                 camera.controls.truck(0, +0.25, true)
    //                 console.log('e low')
    //                 break;

    //             default:
    //                 break;
    //         }
    //     }
    //     document.addEventListener('keydown', onKeyDown, false);
    //     document.addEventListener('keyup', function (event) {
    //         // Update the state of the key being released
    //         if (event.key === 'w') {
    //             keys.w = false;
    //         }
    //         if (event.key === 'd') {
    //             keys.d = false;
    //         }
    //         if (event.key === 's') {
    //             keys.s = false;
    //         }
    //         if (event.key === 'a') {
    //             keys.a = false;
    //         }
    //     });

    //     // components.camera = new OBC.SimpleCamera(components);
    //     components.raycaster = new OBC.SimpleRaycaster(components);
    //     components.init();
    //     scene1 = components.scene.get();
    //     fragments = new OBC.FragmentManager(components);
    //     fragmentIfcLoader = new OBC.FragmentIfcLoader(components);
    //     await fragmentIfcLoader.setup()
    //     propsProcessor = new OBC.IfcPropertiesProcessor(components);
    //     propsManager = new OBC.IfcPropertiesManager(components);
    //     propsProcessor.propertiesManager = propsManager;

    //     // const propsFinder = new OBC.IfcPropertiesFinder(components);
    //     //new
    //     components.renderer.postproduction.enabled = true;
    //     components.renderer.postproduction.customEffects.outlineEnabled = true;

    //     const highlighter = new OBC.FragmentHighlighter(components, fragments);
    //     highlighter.setup();
    //     highlighter.outlinesEnabled = true;
    //     highlighter.updateHighlight();


    //     fragmentIfcLoader.onIfcLoaded.add(async () => {
    //         const highlighterEvents = highlighter.events;

    //         highlighterEvents['select'].onClear.add(() => {
    //             console.log('onClear');
    //         });

    //         highlighterEvents['select'].onHighlight.add(
    //             (selection) => {
    //                 console.log('onHighlight');
    //                 const fragmentID = Object.keys(selection)[0];
    //                 const expressID = Number([...selection[fragmentID]][0]);
    //                 let model;
    //                 //             for (const [_key, value] of group.keyFragments) {

    //                 for (const group of fragments.groups) {
    //                     for (const [_key, value] of group.keyFragments) {
    //                         if (value === fragmentID) {
    //                             model = group;
    //                             console.log('model onHighlight', model);

    //                             break;
    //                         }
    //                     }
    //                 }

    //                 if (model) {
    //                     console.log('model>>', model.width)
    //                     propsProcessor.renderProperties(model, expressID);
    //                 }
    //             }
    //         );

    //         await highlighter.updateHighlight();
    //     });



    //     const mainToolbar = new OBC.Toolbar(components);
    //     components.ui.addToolbar(mainToolbar);
    //     mainToolbar.addChild(propsProcessor.uiElement.get("main"));

    // }

    async function init() {
        setLoading(false);
        components1 = new OBC1.Components();
        // components = new OBC.Components();
        componentsRef.current = components1
        const worlds = components1.get(OBC1.Worlds);


        world = worlds.create(OBC1.SimpleScene,
            OBC1.OrthoPerspectiveCamera,
            OBCF.PostproductionRenderer)
        world.scene = new OBC1.SimpleScene(components1);
        world.renderer = new OBCF.PostproductionRenderer(components1, canvasRef.current);
        world.camera = new OBC1.OrthoPerspectiveCamera(components1)
        controls = world.camera.controls
        controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY
        world.camera.controls.setLookAt(5, 5, 5, 0, 0, 0);
        world.scene.setup();
        const backgroundColor = new THREE.Color("#dad8d8");
        world.scene.three.background = backgroundColor;
        controls.infinityDolly = false
        controls.minDistance = 0.001; //dolly
        controls.maxDistance = 300; //dolly
        controls.mouseButtons.middle = CameraControls.ACTION.TRUCK;
        controls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
        controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
        controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
        controls.truckSpeed = 5;
        controls.dollySpeed = 6
        //end new version
        components1.camera = world.camera;
        componentsRef.current = components1
        // components1.init();

        let keys = {
            w: false,
            d: false,
            a: false,
            s: false
        };
        function onKeyDown(event) {

            if (event.key === 'w') {
                keys.w = true;
            }
            if (event.key === 'd') {
                keys.d = true;
            }
            if (event.key === 'a') {
                keys.a = true;
            }
            if (event.key === 's') {
                keys.s = true;
            }

            if (keys.w && keys.d) {
                world.camera.controls.forward(0.5, true)
                world.camera.controls.truck(0.5, 0, true)
            }
            if (keys.w && keys.a) {
                world.camera.controls.forward(0.5, true)
                world.camera.controls.truck(-0.5, 0, true)
            }
            if (keys.s && keys.a) {
                world.camera.controls.forward(-0.5, true)
                world.camera.controls.truck(-0.5, 0, true)
            }
            if (keys.s && keys.d) {
                world.camera.controls.forward(-0.5, true)
                world.camera.controls.truck(0.5, 0, true)
            }

            switch (event.keyCode) {
                case 37:// left
                    world.camera.controls.rotate(+0.1, 0, true)
                    break
                case 65:
                    world.camera.controls.truck(-0.25, 0, true)
                    break;
                case 38:
                case 87:
                    if (event.shiftKey) {
                        console.log('shift up')
                        world.camera.controls.forward(1.5, true)
                        break;

                    }
                    console.log('up')
                    world.camera.controls.forward(0.25, true)
                    break;
                case 39:
                    world.camera.controls.rotate(-0.1, 0, true)
                    break
                case 68:
                    console.log('right')
                    world.camera.controls.truck(0.25, 0, true)
                    break;
                case 40:
                case 83:
                    if (event.shiftKey) {
                        console.log('shift down')
                        world.camera.controls.forward(-1.5, true)
                        break;

                    }
                    console.log('down')
                    world.camera.controls.forward(-0.25, true)
                    break;
                case 81:
                    world.camera.controls.truck(0, -0.25, true)
                    console.log('q top')
                    break;
                case 69:
                    world.camera.controls.truck(0, +0.25, true)
                    console.log('e low')
                    break;

                default:
                    break;
            }
        }
        document.addEventListener('keydown', onKeyDown, false);
        document.addEventListener('keyup', function (event) {
            // Update the state of the key being released
            if (event.key === 'w') {
                keys.w = false;
            }
            if (event.key === 'd') {
                keys.d = false;
            }
            if (event.key === 's') {
                keys.s = false;
            }
            if (event.key === 'a') {
                keys.a = false;
            }
        });

        const grid = components1.get(OBC1.Grids).create(world);
        grid.three.position.y -= 1;
        grid.fade = true;


        const { postproduction } = world.renderer;
        postproduction.enabled = true;
        postproduction.customEffects.excludedMeshes.push(grid.three);
        world.renderer.postproduction.customEffects.excludedMeshes.push(grid.three);

        const directionalLight = new THREE.DirectionalLight();
        directionalLight.position.set(5, 10, 3);
        directionalLight.intensity = 1;
        world.scene.three.add(directionalLight);

        const ambientLight = new THREE.AmbientLight();
        ambientLight.intensity = 1;
        world.scene.three.add(ambientLight);

        fragments = components1.get(OBC1.FragmentsManager);
        fragmentIfcLoader = components1.get(OBC1.IfcLoader);
        await fragmentIfcLoader.setup();
        fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
        fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true;
        indexer = components1.get(OBC1.IfcRelationsIndexer);

        const [propertiesTable, updatePropertiesTable] = UIOBC.tables.elementProperties({
            components: components1,
            fragmentIdMap: {},
        });
        propertiesTable.preserveStructureOnFilter = true;
        propertiesTable.indentationInText = false;

        const VIEWER_HIGHLIGHT_COLOR = new THREE.Color("rgb(100, 100, 100)");
        const VIEWER_SELECT_COLOR = new THREE.Color("#002D62");
        const VIEWER_HOVER_COLOR = new THREE.Color("#2793D0");

        highlighter = new OBCF.Highlighter(components1);
        const highlighterConfig = {
            selectName: "select",
            hoverName: "preselect",
            selectionColor: VIEWER_SELECT_COLOR,
            hoverColor: VIEWER_HOVER_COLOR,
            autoHighlightOnClick: true,
            world: world,
        };
        highlighter.setup(highlighterConfig);
        highlighter.add("highlight", VIEWER_HIGHLIGHT_COLOR);
        highlighter.events.select.onHighlight.add((fragmentIdMap) => {
            updatePropertiesTable({ fragmentIdMap });
            // console.log('propertiesTable>>', propertiesTable)
            if (componentsRef.current.EdgeMeasurement.enabled) {
                componentsRef.current.EdgeMeasurement.snapDistance = 1;
                componentsRef.current.EdgeMeasurement.create()
                clipperBolean.current = false
                componentsRef.current.areaDims.enabled = false;
                componentsRef.current.dimensions.enabled = false;
                componentsRef.current.angles.enabled = false;

            }
            if (componentsRef.current.angles.enabled) {
                componentsRef.current.angles.snapDistance = 1;
                componentsRef.current.angles.create()
                clipperBolean.current = false
                componentsRef.current.areaDims.enabled = false;
                componentsRef.current.dimensions.enabled = false;
                componentsRef.current.EdgeMeasurement.enabled = false;

            }
            if (componentsRef.current.areaDims.enabled) {
                componentsRef.current.areaDims.snapDistance = 1;
                componentsRef.current.areaDims.create()
                clipperBolean.current = false
                componentsRef.current.angles.enabled = false;
                componentsRef.current.dimensions.enabled = false;
                componentsRef.current.EdgeMeasurement.enabled = false;

            }

        });

        highlighter.events.select.onClear.add(() =>
            updatePropertiesTable({ fragmentIdMap: {} }),
        );

        const propertiesPanel = openUI.Component.create(() => {
            const onTextInput = e => {
                const input = e.target
                propertiesTable.queryString = input.value !== "" ? input.value : null
            }

            const expandTable = e => {
                const button = e.target
                propertiesTable.expanded = !propertiesTable.expanded
                button.label = propertiesTable.expanded ? "Collapse" : "Expand"
            }

            propertiesTable.expanded = !propertiesTable.expanded

            const copyAsTSV = async () => {
                await navigator.clipboard.writeText(propertiesTable.tsv)
            }

            return openUI.html`
                        <bim-panel  label="Properties">
                          <bim-panel-section  flex  label="Element Data">
                            <div style="display: flex; gap: 0.5rem;">
                              <bim-button @click=${expandTable} label=${propertiesTable.expanded ? "Collapse" : "Expand"}></bim-button> 
                              <bim-button @click=${copyAsTSV} label="Copy as TSV"></bim-button> 
                            </div> 
                            <bim-text-input @input=${onTextInput} placeholder="Search Property"></bim-text-input>
                            ${propertiesTable}
                          </bim-panel-section>
                        </bim-panel>`
        })
        // console.log("propertiesPanel>>", propertiesPanel);

        propertiesRef.current.appendChild(propertiesPanel)

        const edge = new OBCF.EdgeMeasurement(components1)
        edge.world = world;
        edge.enabled = false;
        edge.color = new THREE.Color("#2793D0");
        edge.snapDistance = 1
        components1.EdgeMeasurement = edge

        const angles = new OBCF.AngleMeasurement(components1)
        angles.world = world;
        angles.enabled = false;
        angles.color = new THREE.Color("#2793D0");
        angles.snapDistance = 1
        components1.angles = angles

        components1.areaDims = new OBCF.AreaMeasurement(components1);
        components1.areaDims.world = world
        components1.areaDims.enabled = false

        components1.dimensions = new OBCF.LengthMeasurement(components1);
        components1.dimensions.world = world
        components1.dimensions.enabled = false
        components1.dimensions.snapDistance = 1;

        canvasRef.current.ondblclick = () => {
            if (componentsRef.current.dimensions.enabled)
                componentsRef.current.dimensions.create();
            // components.dimensions.create()
            console.log('---ondblclick')
            if (clipperBolean.current) {
                console.log('---clipperBolean')
                clipper.enabled = true;
                clipper.create(world);

            }
        };

        window.onkeydown = (event) => {
            if (event.code === "Delete" || event.code === "Backspace") {
                components.angles.delete();
                components.angles.enabled = false;

            }
            if (event.code === 'Escape') {
                console.log('escape')
                if (componentsRef.current.areaDims.enabled) {
                    componentsRef.current.areaDims.endCreation()
                    componentsRef.current.areaDims.enabled = false

                }
                if (componentsRef.current.angles.enabled)
                    componentsRef.current.angles.enabled = false

                if (componentsRef.current.dimensions.enabled)
                    componentsRef.current.dimensions.enabled = false

            }
        };

        casters = components1.get(OBC1.Raycasters);
        casters.get(world);

        clipper = components1.get(OBC1.Clipper);
        clipper.enabled = true;
        components1.clipper = clipper

        edges = components1.get(OBCF.ClipEdges);
        clipper.Type = OBCF.EdgesPlane;

        blueFill = new THREE.MeshBasicMaterial({ color: "lightblue", side: 2 });
        blueLine = new THREE.LineBasicMaterial({ color: "blue" });
        blueOutline = new THREE.MeshBasicMaterial({
            color: "blue",
            opacity: 0.5,
            side: 2,
            transparent: true,
        });

        plans = components1.get(OBCF.Plans);
        plans.world = world;

        // cullers = components1.get(OBC1.Cullers);
        // culler = cullers.create(world);


        // world.camera.controls.addEventListener("control", () => {
        //     console.log('cameraControls');
        //     culler.needsUpdate = true;
        // });
        // world.camera.controls.addEventListener("controlstart", () => {
        //     console.log('cameraControls');
        //     culler.needsUpdate = true;
        // });
        // world.camera.controls.addEventListener("controlend", () => {
        //     console.log('cameraControls');
        //     culler.needsUpdate = true;
        // });
        // world.camera.controls.addEventListener("update", () => {
        //     console.log('cameraControls');
        //     culler.needsUpdate = true;
        // });
        // world.camera.controls.addEventListener("wake", () => {
        //     console.log('cameraControls');
        //     culler.needsUpdate = true;
        // });
        // world.camera.controls.addEventListener("rest", () => {
        //     console.log('cameraControls');
        //     culler.needsUpdate = true;
        // });
        // world.camera.controls.addEventListener("sleep", () => {
        //     console.log('cameraControls');
        //     culler.needsUpdate = true;
        // });

        classifier = components1.get(OBC1.Classifier);


        const maps = new OBC1.MiniMaps(components1);
        mapRef.current = maps.create(world);
        const canvas = mapRef.current.renderer.domElement;
        canvas.style.borderRadius = "12px";
        canvasMinimap.current.appendChild(canvas);
        mapRef.current.resize();
        const mapSize = mapRef.current.getSize();
        // const panel = openUI.Component.create(() => {
        //     return openUI.html`
        //       <bim-panel label="Minimap Tutorial" class="options-menu">
        //         <bim-panel-section collapsed label="Controls">

        //           <bim-checkbox checked="true" label="Enabled" 
        //             @change="${({ target }) => {
        //             mapRef.current.enabled = target.value
        //         }}">  
        //           </bim-checkbox>

        //           <bim-checkbox checked label="Lock rotation" 
        //             @change="${({ target }) => {
        //             mapRef.current.lockRotation = target.value
        //         }}">  
        //           </bim-checkbox>

        //           <bim-number-input 
        //             slider label="Zoom" value="${mapRef.current.zoom
        //         }" min="0.01" max="0.5" step="0.01" 
        //             @change="${({ target }) => {
        //             mapRef.current.zoom = target.value
        //         }}">
        //           </bim-number-input>

        //           <bim-number-input 
        //             slider label="Front offset" value="${mapRef.current.frontOffset
        //         }" min="0" max="5" step="1" 
        //             @change="${({ target }) => {
        //             mapRef.current.frontOffset = target.value
        //         }}">
        //           </bim-number-input>

        //           <div style="display: flex; gap: 12px">

        //             <bim-number-input slider value="${mapSize.x
        //         }" pref="Size X" min="100" max="500" step="10"              
        //               @change="${({ target }) => {
        //             const size = mapRef.current.getSize()
        //             console.log("size>>>>", target.value);
        //             size.x = target.value
        //             mapRef.current.resize(size)
        //             console.log("Map>>>", mapRef.current);
        //         }}">
        //             </bim-number-input>        

        //             <bim-number-input slider value="${mapSize.y
        //         }" pref="Size Y" min="100" max="500" step="10"            
        //               @change="${({ target }) => {
        //             const size = mapRef.current.getSize()
        //             size.y = target.value
        //             mapRef.current.resize(size)
        //         }}">
        //             </bim-number-input>
        //           </div>


        //         </bim-panel-section>
        //       </bim-panel>
        //       `
        // })


        // canvas.append(panel);
        // minimapPanelRef.current.appendChild(panel)

        components1.init();
        componentsRef.current = components1


    }

    useEffect(() => {

        if (firstload.current === true) {

            init().then(() => {
                setFilePath(localStorage.getItem("filePath"))
                fileURL.current = localStorage.getItem("filePath")
                loadIfcAsFragments();
                const saveFileCode1 = localStorage.getItem('showId');

            });
            firstload.current = false
        }

    }, [size1.width])



    useEffect(() => {
        if (downloadProgressRef.current > 99 || downloadProgress >= 99) {
            setLoading(true)
        }
        setDownloadProgress(downloadProgressRef.current);
    }, [downloadProgressRef.current]);


    const [isFullScreen, setIsFullScreen] = useState(false)

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((err) => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullScreen(false)
            }
        }
    };

    function handeWalk() {
        setBackgroundBtn(false);
        componentsRef.current.camera.set("FirstPerson");
    }

    function handeEdgeMeasurement() {

        console.log('Edge')
        clipperBolean.current = false
        componentsRef.current.EdgeMeasurement.enabled = true;
        componentsRef.current.EdgeMeasurement.snapDistance = 1;
        componentsRef.current.EdgeMeasurement.create()
        componentsRef.current.areaDims.enabled = false;
        componentsRef.current.dimensions.enabled = false;
        componentsRef.current.angles.enabled = false;
        setMeasurementMenu(false);

    }

    function handeAngleMeasurement() {
        console.log('angle')
        clipperBolean.current = false
        componentsRef.current.angles.enabled = true;
        componentsRef.current.angles.snapDistance = 1;
        componentsRef.current.angles.create()
        componentsRef.current.areaDims.enabled = false;
        componentsRef.current.dimensions.enabled = false;
        componentsRef.current.EdgeMeasurement.enabled = false;
        setMeasurementMenu(false);
    }

    function handeAreaMeasurement() {
        console.log('area')
        clipperBolean.current = false
        componentsRef.current.angles.enabled = false;
        componentsRef.current.areaDims.enabled = true;
        componentsRef.current.dimensions.enabled = false;
        setMeasurementMenu(false);
    }

    function handeLenghtMeasurement() {
        console.log('lenght')
        clipperBolean.current = false
        componentsRef.current.angles.enabled = false;
        componentsRef.current.areaDims.enabled = false;
        componentsRef.current.dimensions.enabled = true;
        componentsRef.current.dimensions.snapDistance = 1;
        setMeasurementMenu(false);
    }

    function handeClearMeasurement() {
        console.log('delete all')
        componentsRef.current.angles.deleteAll();
        componentsRef.current.angles.enabled = false;
        componentsRef.current.areaDims.deleteAll()
        componentsRef.current.areaDims.enabled = false;
        componentsRef.current.dimensions.deleteAll()
        componentsRef.current.dimensions.enabled = false;
        componentsRef.current.EdgeMeasurement.deleteAll()
        componentsRef.current.EdgeMeasurement.enabled = false;
        componentsRef.current.clipper.enabled = false;
        componentsRef.current.clipper.dispose()
        clipperBolean.current = false
        console.log(componentsRef.current.clipper)
        console.log(componentsRef.current)

    }

    function handleOrbitCamera() {
        setBackgroundBtn(true);
        // componentsRef.current.camera.setNavigationMode("Orbit");
        componentsRef.current.camera.set("Orbit");

    }

    function handleResetCamera() {
        console.log('reset camera')
        componentsRef.current.camera.controls.setLookAt(5, 5, 5, 0, 0, 0);

    }

    function handleProperties() {
        let propertiesPanel = document.getElementById("propertiesPanel");
        if (!isPropertiesPanel) {
            setIsPropertiesPanel(true);
            propertiesPanel.style.visibility = "visible";
            propertiesPanel.className = "bimPanel"
        } else {
            setIsPropertiesPanel(false);
            propertiesPanel.style.visibility = "hidden";
        }
    }

    useEffect(() => {
        const handleClick = () => {
            let propertiesPanel = document.getElementById("propertiesPanel");
            setIsPropertiesPanel(false);
            propertiesPanel.style.visibility = "hidden";
        };

        // document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const handleContextMenu = (e) => {
        e.preventDefault();
        let propertiesPanel = document.getElementById("propertiesPanel");
        setIsPropertiesPanel(true);
        propertiesPanel.style.visibility = "visible";
        propertiesPanel.className = "bimPanel"
    };


    function handleClipper() {
        console.log("handleClipper")
        componentsRef.current.angles.enabled = false;
        componentsRef.current.areaDims.enabled = false;
        componentsRef.current.dimensions.enabled = false;
        componentsRef.current.EdgeMeasurement.enabled = false;

        clipperBolean.current = true;
    }

    function handlePlans() {
        let plansPanel = document.getElementById("plansPanel");
        if (!isPlans) {
            setIsPlans(true);
            plansPanel.style.visibility = "visible";
            plansPanel.className = "bimPanel"
        } else {
            setIsPlans(false);
            plansPanel.style.visibility = "hidden";
        }
    }

    function handleMiniMap() {
        // let minimapPanel = document.getElementById("minimapPanel");
        let minimap = document.getElementById("minimap");
        if (!isMinimapPanel) {
            setIsMinimapPanel(true);
            // minimapPanel.style.visibility = "visible";
            minimap.style.visibility = "visible";
            // minimapPanel.className = "bimPanel"
        } else {
            setIsMinimapPanel(false);
            // minimapPanel.style.visibility = "hidden";
            minimap.style.visibility = "hidden";
        }
    }

    function handleMeasurementMenu() {
        if (!measurementMenu) {
            setMeasurementMenu(true);
        } else {
            setMeasurementMenu(false);
        }
    }

    const startResize = (e) => {
        e.preventDefault();
        console.log("startResize");
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResize);

    };

    const resize = (e) => {
        const newWidth = e.clientX - canvasMinimap.current.getBoundingClientRect().left;
        const newHeight = canvasMinimap.current.getBoundingClientRect().bottom - e.clientY;
        const size = mapRef.current.getSize()
        size.x = newWidth;
        size.y = newHeight;
        mapRef.current.resize(size)
        console.log("e.clientY>>", e.clientY);
        console.log("canvasMinimap.current.getBoundingClientRect().top>>", canvasMinimap.current.getBoundingClientRect().top);
    };

    const stopResize = () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResize);
    };

    const handleInputZoom = (e) => {
        const newScale = e.target.value;
        console.log(newScale);
        mapRef.current.zoom = newScale;
    }

    const handlePositionPro = (e) => {
        draggingPro.current = true;
        offsetPro.current = {
            x: e.clientX - positionPro.left,
            y: e.clientY - positionPro.top
        };
        console.log("offsetPro.current>>", offsetPro.current);
        console.log("e.clientX>>", e.clientX);
        console.log("positionPro.left>>", positionPro.left);
    };

    const handleMouseMovePro = (e) => {
        if (draggingPro.current) {
            setPositionPro({
                left: e.clientX - offsetPro.current.x,
                top: e.clientY - offsetPro.current.y
            });
        }
    };

    const handleMouseUpPro = () => {
        draggingPro.current = false;
    };


    return (

        <>

            <div id="project3D" className="Canvas-tag" ref={canvasRef}
                style={{ height: "100%" }}
                onContextMenu={handleContextMenu}
            >
                <div className="Canvas-tag-2" id="minimap" >

                    <div ref={canvasMinimap}
                        onMouseDown={startResize}
                        style={{ width: "auto" }}
                    >
                    </div>
                    <input type="range"
                        class="form-range"
                        id="customRange1"
                        min="0.01"
                        max="0.5"
                        step="0.01"
                        onChange={handleInputZoom}
                    />
                </div>

                <div className="Bottom-Navbar" dir="ltr" id="salam">
                    <div className="Bottom-Navbar-container">
                        <div className="Measurement-Bottom-Navbar">
                            <button type="button" className="Measurement-Bottom-Navbar-btn "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={handleMeasurementMenu}
                            >
                                <Icon.CaretLeftFill size={"10px"} color="#fff" />
                                <img src={lenght} alt="" className="Bottom-Navbar-img-measurement" />
                            </button>
                            <ul className="dropdown-menu"
                                style={{ display: measurementMenu ? "block" : "none" }}
                            >
                                <li className="dropdown-menu-li">
                                    <button className="Edge-Bottom-Navbar-btn"
                                        title="Edge"
                                        onClick={handeEdgeMeasurement}

                                    >
                                        <img src={edge} alt="" className="Bottom-Navbar-img" />
                                        <span>Edge</span>
                                    </button>
                                </li>
                                <li className="dropdown-menu-li">
                                    <button className="Angle-Bottom-Navbar-btn"
                                        title="angle"
                                        onClick={handeAngleMeasurement}

                                    >
                                        <img src={angle} alt="" className="Bottom-Navbar-img" />
                                        <span>Angle</span>
                                    </button>
                                </li>
                                <li className="dropdown-menu-li">
                                    <button className="Area-Bottom-Navbar-btn"
                                        title="area"
                                        onClick={handeAreaMeasurement}

                                    >
                                        <img src={area} alt="" className="Bottom-Navbar-img" />
                                        <span>Area</span>
                                    </button>
                                </li>
                                <li className="dropdown-menu-li">
                                    <button className="Lenght-Bottom-Navbar-btn"
                                        title="length"
                                        onClick={handeLenghtMeasurement}
                                    >
                                        <img src={lenght} alt="" className="Bottom-Navbar-img" />
                                        <span>Length</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <button onClick={toggleFullScreen} className="Full-Bottom-Navbar-btn" title="Fullscreen">
                            {isFullScreen ? (
                                <img src={noFullScreen} alt="" className="Bottom-Navbar-img" />
                            ) : (

                                <img src={fullSize} alt="" className="Bottom-Navbar-img" />
                            )}
                        </button>
                        <button className="Walk-Bottom-Navbar-btn"
                            title="walk"
                            onClick={handeWalk}
                            style={{ backgroundColor: backgroundBtn ? "#002D62" : "#2793D0" }}
                        >
                            <img src={walking} alt="" className="Bottom-Navbar-img" />
                        </button>
                        <button className="Clear-Bottom-Navbar-btn"
                            title=" clear"
                            onClick={handeClearMeasurement}

                        >
                            <img src={clear} alt="" className="Bottom-Navbar-img" />
                        </button>
                        <button className="Reset-Bottom-Navbar-btn" title="reset camera" onClick={handleResetCamera}>
                            <img src={reDo} alt="" className="Bottom-Navbar-img" title="reset camera" />
                        </button>
                        <button className="Orbit-Bottom-Navbar-btn"
                            title="Orbit"
                            onClick={handleOrbitCamera}
                            style={{ backgroundColor: backgroundBtn ? "#2793D0" : "#002D62" }}
                        >
                            <img src={rotation} alt="" className="Bottom-Navbar-img" title="Orbit" />
                        </button>
                        <GuideBox />
                        <button className="Properties-Bottom-Navbar-btn"
                            title="Properties"
                            onClick={handleProperties}
                            style={{ backgroundColor: isPropertiesPanel ? "#2793D0" : "#002D62" }}
                        >
                            <img src={menu} alt="" className="Bottom-Navbar-img" title="Properties" />
                        </button>
                        <button className="Clipper-Bottom-Navbar-btn"
                            title="Clipper"
                            onClick={handleClipper}
                        >
                            <img src={clipperImg} alt="" className="Bottom-Navbar-img" title="Clipper" />
                        </button>
                        <button className="Plans-Bottom-Navbar-btn"
                            title="Plans"
                            onClick={handlePlans}
                            style={{ backgroundColor: isPlans ? "#2793D0" : "#002D62" }}
                        >
                            <img src={plansImg} alt="" className="Bottom-Navbar-img" title="Plans" />
                        </button>
                        <button className="Minimap-Bottom-Navbar-btn"
                            title="Map"
                            onClick={handleMiniMap}
                            style={{ backgroundColor: isMinimapPanel ? "#2793D0" : "#002D62" }}
                        >
                            <img src={mapImg} alt="" className="Bottom-Navbar-img" title="Map" />
                        </button>
                    </div>
                </div>
                <div ref={propertiesRef} className="properties-container"
                    style={{ top: `${positionPro.top}px`, left: `${positionPro.left}px` }}
                    onMouseDown={handlePositionPro}
                    onMouseMove={handleMouseMovePro}
                    onMouseUp={handleMouseUpPro}
                ></div>

                <div ref={minimapPanelRef} className="minimapPanel-container"></div>
                <div ref={plansRef} className="plansPanel-container"></div>
            </div>

            {downloadProgress !== 100 && downloadProgress < 99 && (
                <div className="progress-bar3" >
                    <div>
                        <div className="outer">
                            <div className="inner">
                                <div id="number">{downloadProgress}</div>
                            </div>
                        </div>
                        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient id="Gradient">
                                    <stop stopColor="#da22ff" offset="0%" />
                                    <stop stopColor="#9733ee" offset="100%" />
                                </linearGradient>
                            </defs>
                            <circle cx="50" cy="50" r="42" style={{ strokeDasharray: `265 265`, strokeDashoffset: `calc(265 - (265 * ${downloadProgress}) / 100)` }} />
                        </svg>
                    </div>
                </div>
            )}

        </>

    )
}