import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import * as Icon from "react-bootstrap-icons";
import InputGroup from "react-bootstrap/InputGroup";
import "./ModalShare.css";

export default function ShareProject(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);
  const copyTextToClipboard1 = () => {
    textareaRef1.current.select();
    document.execCommand("copy");
  };
  const copyTextToClipboard2 = () => {
    textareaRef2.current.select();
    document.execCommand("copy");
  };

  const preview = () => {
    handleClose();
    window.open(
      `https://platform.solidclouds.nl/${props.fileCodeParam}`,
      "_blank"
    );
  };

  // console.log("props.fileCodeParam>>", props.fileCodeParam);

  return (
    <>

      <div className="Share-project" onClick={handleShow}>
        <div className="Share-project-btn">
          <span className="Share-project-btn-icon">
            <Icon.ShareFill color="#ffffff" size={"18px"} />
          </span>
          Share
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="Share-window-title">Share Links</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="Share-window-form-label">
                By using the code below, you can embed a 3D view on your
                website.
              </Form.Label>
              <InputGroup dir="ltr">
                <InputGroup.Text>
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary Copy-btn"
                      title="copy"
                      onClick={(e) => {
                        e.preventDefault();
                        copyTextToClipboard1();
                      }}
                    >
                      <Icon.Copy color="#2793D0" />
                    </button>
                  </div>
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={6}
                  aria-label="With textarea"
                  ref={textareaRef1}
                  value={`<iframe src="https://platform.solidclouds.nl/${props.fileCodeParam}" allowFullScreen="true" webkitallowfullscreen="true" mozallowfulscreen="true" frameBorder={0} width="1024" height="768"></iframe>`}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Label className="Share-window-form-label">
                By using the link below, you can share the project page with
                your colleagues.
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary Copy-btn"
                      title="copy"
                      onClick={(e) => {
                        e.preventDefault();
                        copyTextToClipboard2();
                      }}
                    >
                      <Icon.Copy color="#2793D0" />
                    </button>
                  </div>
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  ref={textareaRef2}
                  rows={4}
                  aria-label="With textarea"
                  value={`https://platform.solidclouds.nl/${props?.fileCodeParam}`}
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="Share-buttons">
          <div className="Share-buttons-left">
            <Button variant="success" className="Preview-btn" onClick={preview}>
              Preview
            </Button>
          </div>
          <div className="Share-buttons-right">
            <Button
              variant="light"
              className="Close-window-btn"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
