import * as Icon from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";
import img1 from "../../../assets/img1.png";
import back from "../../../assets/left.png";
import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "../../../Context/contextState";
import { useNavigate, useParams } from "react-router-dom";
import RemoveFileBox from "../../../GelobalComponents/MoadalRemove/ModalFileRemove";
import "./projectFiles.css";
import axios from "axios";
import Toasts from "../../../GelobalComponents/Toast/Toasts";
import Loading from "../../../GelobalComponents/Loading/Loading";

export default function ProjectFiles() {
  const [projectsFile, setProjectsFile] = useState([]);
  const {
    loading,
    setLoading,
    projectId,
    setProjectId,
    projectTitle,
    setProjectTitle,
    setFileDetail,
  } = useContext(AppStateContext);

  const navigate = useNavigate();
  const [uploaded, setUploaded] = useState(0);
  const [overLay, setOverlay] = useState(false);
  const [toastsShow, setToastsShow] = useState(false);
  const [toastsShowBack, setToastsShowBack] = useState(false);
  const [toastsText, setToastsText] = useState();
  const [height1, setHeight1] = useState(window.innerHeight);

  const handleResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setHeight1(height);
    console.log(`Resized to Width: ${width}, Height: ${height}`);
  };

  const fetchData = async () => {
    await setLoading(true);
    const savedProjectId = localStorage.getItem("projectId");
    const savedProjectTitle = localStorage.getItem("projectTitle");
    if (savedProjectId) {
      setProjectId(savedProjectId);
      setProjectTitle(savedProjectTitle);
    }
    try {
      const response = await fetch(
        `https://api.solidclouds.nl/api/files/${savedProjectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setProjectsFile(data.info.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setUploaded(0);
  };
  const savedProjectId = localStorage.getItem("projectId");

  useEffect(() => {
    fetchData();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [savedProjectId]);

  const handleFileChange1 = async (event) => {
    setUploaded(1);
    setOverlay(true);
    const filebox1 = event.target.files[0];
    if (!filebox1.name.endsWith(".ifc")) {
      alert("Please upload a valid .ifc file.");
      return;
    }
    const filebox = event.target?.files[0];
    const formdata = new FormData();
    formdata.append("title", filebox?.name);
    formdata.append("file", filebox);
    try {
      const response = await axios.post(
        `https://api.solidclouds.nl/api/files/${savedProjectId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploaded(percentage - 1);
          },
        }
      );
      if (response.status === 201) {
        console.log(response);
        setUploaded(0);
        await fetchData();
        setOverlay(false);
        setToastsShow(true);
        setToastsShowBack(true);
        setToastsText("Upload Successfully");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setToastsShow(true);
      setToastsShowBack(false);
      setUploaded(0);
      setOverlay(false);
      setToastsText("ERROR : File wasn't uploaded");
    } finally {
      setLoading(false);
      setTimeout(() => {
        setToastsShow(false);
      }, 6000);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
    setLoading(false);
  };

  const handleFileDetail = (fileId, filePath, fileTitle, fileCode) => {
    setFileDetail({
      fileId: fileId,
      filePath: filePath,
      fileTitle: fileTitle,
      fileCode: fileCode,
    });

    localStorage.setItem("fileId", fileId);
    localStorage.setItem("filePath", filePath);
    localStorage.setItem("fileTitle", fileTitle);
    localStorage.setItem("fileCode", fileCode);
    console.log("fileId>>", fileId);
  };

  let height2 = height1 * 0.8;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <header className="Header-page">
        <div className="container">
          <div className="Header-container">
            <div className="Header-content">
              <div className="Backward">
                <button type="button" onClick={handleBack} title="Back">
                  <img src={back} alt="back" />
                </button>
              </div>
              <div className="Header-logo">
                <img src={img1} alt="" />
              </div>
              <div className="Header-title">
                <h3>3DPanel</h3>
              </div>
            </div>
            <div className="Header-buttons">
              <div className="Project-file-UploadBtn">
                <label className="file-upload-label">
                  <span>Upload File (.ifc)</span>
                  <input
                    type="file"
                    multiple
                    name="file1"
                    id="fileUpload"
                    accept=".ifc"
                    onChange={handleFileChange1}
                  />
                </label>
              </div>
              <div className="Exit-btn">
                <button type="button" onClick={handleLogout}>
                  <Icon.BoxArrowLeft size={"25px"} color="#002D62" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="Project-file-page">
        <div className="Project-file-page-heading">
          <div className="container">
            <div className="BreadcrumbPage">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">Projects</li>
                  <li class="breadcrumb-item">{projectTitle}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="container">
          {uploaded > 0 && (
            <div
              className="progress ProgressBar2"
              dir="ltr"
              role="progressbar"
              aria-label="Example with label"
              aria-valuenow={uploaded}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div class="progress-bar" style={{ width: `${uploaded}%` }}>
                {uploaded}%
              </div>
            </div>
          )}
        </div>
        <div className="Project-file-tabel" style={{ height: `${height2}px` }}>
          <div className="container">
            <div className="Project-file-title-group">
              <ul className="Project-file-title-group-container row">
                <li className="Project-file-title-group-item col-md-4 col-6">
                  Name
                </li>
                <li className="Project-file-title-group-item col-md-4 col-5 p-0">
                  Date
                </li>
                <li className="Project-file-title-group-item col-md-4 col-1"></li>
              </ul>
            </div>
            <div className="Projects-list">
              <ul>
                {loading ? (
                  <Loading />
                ) : (
                  projectsFile.map((file, key) => {
                    return (
                      <li key={file.id} className="Projects-list-item">
                        <ul className="row py-3">
                          <li className="Projects-list-item-title col-md-4 col-6">
                            <a
                              href={`/projects/${projectId}/${file.id}`}
                              className="Projects-list-item-link"
                              onClick={() => {
                                handleFileDetail(
                                  file.id,
                                  file.path,
                                  file.title,
                                  file?.code
                                );
                                setLoading(true);
                              }}
                            >
                              <Icon.FileEarmark
                                color="#000"
                                style={{ marginRight: "10px" }}
                                size={"20px"}
                              />
                              <span>{file.title}</span>
                            </a>
                          </li>
                          <li className="Projects-list-item-date col-md-4 col-5 p-0">
                            {file.created_at}
                          </li>
                          <li className="Projects-list-item-actions col-md-4 col-1">
                            <RemoveFileBox
                              itemId={file.id}
                              fetchData={fetchData}
                            />
                          </li>
                        </ul>
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
      {overLay && <div className="Overlay-div"></div>}
      <Toasts
        toastsShow={toastsShow}
        toastsText={toastsText}
        toastsShowBack={toastsShowBack}
      />
    </>
  );
}
