import './Loading.css'

export default function Loading() {
  return (
    <>
      <div className="Loading-container">
        <span className="loader0"></span>
        <span className="loader0-title">Please wait ...</span>
      </div>
    </>
  );
}
