import './App.css';
import './GelobalComponents/font.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppStateContextProvider from './Context/contextState';
import 'bootstrap/dist/css/bootstrap.css'
import { useEffect } from 'react';
import LogIn from './Pages/auth/Login/LoginPage';
import ProjectsPage from './Pages/Projects/ProjectsPage/ProjectsPage';
import ProjectFiles from './Pages/Projects/FilesPage/ProjectFiles';
import FilePage from './Pages/Projects/FilePage/FilePage';
import ProjectPagePubish from './Pages/Projects/FilePage/PublishFie';

function App() {

  useEffect(() => {
    document.title = "3DPanel";
  }, [])
  return (
    <>

      <div className='body'>
        <AppStateContextProvider>
          <Router>
            <Routes>
              <Route path='/' element={<LogIn />} />
              <Route path='/projects' element={<ProjectsPage />} />
              <Route path='/projects/:projectId1' element={<ProjectFiles />} />
              <Route path='/projects/:projectId1/:fileId' element={<FilePage />} />
              <Route path='/:publishId' element={<ProjectPagePubish />} />
            </Routes>
          </Router>
        </AppStateContextProvider>
      </div>

    </>
  );
}

export default App;
