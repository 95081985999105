import "./toasts.css";

export default function Toasts(props) {
  return (
    <>
      <div
        className={
          props.toastsShow
            ? "toast align-items-center Toast show"
            : "toast align-items-center Toast"
        }
        style={{backgroundColor : props.toastsShowBack ? "#abe2ab" : "#ff7a7a"}}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="toast-body">{props.toastsText}</div>
          <button
            type="button"
            className="btn-close Toast-btn"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </>
  );
}
