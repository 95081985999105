import { createContext, useState } from "react";

export const AppStateContext = createContext();

export default function AppStateContextProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [projectId, setProjectId] = useState();
    const [projectTitle, setProjectTitle] = useState('')
    const [fileDetail, setFileDetail] = useState()

    return (
        <AppStateContext.Provider value={{
            loading, setLoading, projectId,
            setProjectId, projectTitle, setProjectTitle, fileDetail, setFileDetail
        }}>
            {children}
        </AppStateContext.Provider>
    )
}