import { useContext, useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import * as Icon from "react-bootstrap-icons";
import { AppStateContext } from "../../../Context/contextState";
import { useNavigate } from "react-router-dom";
import img1 from "../../../assets/img1.png";
import "./LoginPage.css";
import Loading from "../../../GelobalComponents/Loading/Loading";
import { useSpring, animated } from "@react-spring/web";

export default function LogIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [err, setErr] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { loading, setLoading } = useContext(AppStateContext);
  const navigate = useNavigate();
  const parentRef = useRef(null);
  const slideInAnimation = useSpring({
    from: { transform: "translateY(-100%)" },
    to: { transform: "translateY(-3%)" },
    config: { tension: 20, friction: 14 },
  });

  useEffect((e) => {
    if (localStorage.getItem("token")) {
      navigate("/projects");
    } else {
      const savedUsername = localStorage.getItem("username");
      const savedPassword = localStorage.getItem("password");
      const savedRememberMe = localStorage.getItem("rememberMe");
      if (savedRememberMe === "true" && savedUsername && savedPassword) {
        setUsername(savedUsername);
        setPassword(savedPassword);
        setRememberMe(true);
      }
    }
    console.log(parentRef.current);
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      console.log(`Resized to Width: ${width}, Height: ${height}`);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        "https://api.solidclouds.nl/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer 3|zh6OxTQJ2yH7B69Nv8PKVwwopplwiX7CtRn9mccpb8a549d3",
          },
          body: JSON.stringify({ username, password }),
        }
      );
      const data = await response.json();

      if (response.ok) {
        if (rememberMe) {
          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
          localStorage.setItem("rememberMe", "true");
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }
        localStorage.setItem("token", data.info.token);
        navigate("/projects");
      } else {
        setErr(data.message || "Error Login");
      }
    } catch (err) {
      console.error("Error:", err);
      setErr("Error Login");
    }
  };

  return (
    <>
      <main>
        <animated.div className="Form-container" style={slideInAnimation}>
          <Form className="Login-form" onSubmit={handleSubmit} ref={parentRef}>
            <div className="Form-logo">
              <img src={img1} alt="logo" />
            </div>
            <div className="Form-title">
              <h1>Login</h1>
            </div>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1" className="Input-group-icon">
                <Icon.PersonFill color="#2793D0" />
              </InputGroup.Text>
              <Form.Control
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="Login-form-username-input"
                onChange={(e) => setUsername(e.target.value)}
                required
                value={username}
              />
            </InputGroup>

            <InputGroup className="mb-3" dir="ltr">
              <div
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
                className="Input-eye-icon"
              >
                {showPassword ? (
                  <Icon.EyeSlashFill color="#002D62" />
                ) : (
                  <Icon.EyeFill color="#002D62" />
                )}
              </div>
              <InputGroup.Text id="basic-addon1">
                <Icon.ShieldLockFill color="#2793D0" />
              </InputGroup.Text>
              <Form.Control
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                aria-label="Password"
                aria-describedby="basic-addon2"
                className="Login-form-password-input"
                onChange={(e) => setPassword(e.target.value)}
                required
                value={password}
              />
            </InputGroup>

            {err && <p className="Error-message">{err}</p>}

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Remind Me"
                className="Remind-me"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            </Form.Group>

            <div className="Submit-btn">
              <Button variant="primary" type="submit" className="">
                <p className="Submit-btn-p">Login</p>
                <span className="Submit-btn-icon">
                  <Icon.BoxArrowInLeft color="#fff" />
                </span>
              </Button>
            </div>
          </Form>
        </animated.div>
        {loading && <Loading />}
      </main>
    </>
  );
}
