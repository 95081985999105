import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as Icon from "react-bootstrap-icons";
import { AppStateContext } from "../../Context/contextState";
import "./ModalRemove.css";

function RemoveBox(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { loading, setLoading } = useContext(AppStateContext);

  const handleRemove = async (itemId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.solidclouds.nl/api/projects/${itemId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);

      if (response.ok) {
        const data = await response.json();
        props.fetchProjects();
      }
    } catch (err) {
      console.error("Error:", err);
      props.fetchProjects();
      handleClose();
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="primary"
        className="btn btn-primary Card-trashBtn"
        onClick={handleShow}
      >
        <Icon.Trash size={"20px"} color="#002D62" />
      </Button>

      <Modal show={show} onHide={handleClose} className="ModalRem">
        <Modal.Header className="Remove-window-header">
          <Modal.Title className="Remove-window-title">
            <Icon.ExclamationTriangleFill size={"25px"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Remove-window-body">
          Are you certain that you wish to remove this project?
        </Modal.Body>
        <Modal.Footer>
          <div className="Share-buttons-right">
            <Button
              variant="success"
              className="Yes-btn me-1"
              onClick={async () => {
                await handleRemove(props.itemId);
                setLoading(true);
                await props.fetchProjects();
              }}
            >
              Yes
            </Button>
            <Button
              variant="light"
              className="Close-window-btn"
              onClick={handleClose}
            >
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RemoveBox;
