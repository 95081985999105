import { useContext, useEffect, useState } from "react";
import img1 from "../../../assets/img1.png";
import img8 from "../../../assets/img8.png";
import * as Icon from "react-bootstrap-icons";
import "./projectsPage.css";
import { useNavigate } from "react-router-dom";
import CreateProject from "../../../GelobalComponents/ModalCreateProject/ModalCreateProject";
import RemoveBox from "../../../GelobalComponents/MoadalRemove/ModalRemove";
import Toasts from "../../../GelobalComponents/Toast/Toasts";
import { AppStateContext } from "../../../Context/contextState";
import Loading from "../../../GelobalComponents/Loading/Loading";
import dModeling from "../../../assets/3dModeling.png";

export default function ProjectsPage() {
  const [project, setProject] = useState([]);
  const { loading, setLoading, setProjectId, setProjectTitle } =
    useContext(AppStateContext);

  const [toastsShow, setToastsShow] = useState(false);
  const [toastsShowBack, setToastsShowBack] = useState(false);
  const [toastsText, setToastsText] = useState();
  const [height1 , setHeight1] = useState(window.innerHeight);

  const navigate = useNavigate();
  const handleResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setHeight1(height);
    console.log(`Resized to Width: ${width}, Height: ${height}`);
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        "https://api.solidclouds.nl/api/projects",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setProject(data?.info.data);
      setLoading(false);
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const savedProjectId = localStorage.getItem("projectId");
    const savedProjectTitle = localStorage.getItem("projectTitle");
    if (savedProjectId) {
      setProjectId(savedProjectId);
      setProjectTitle(savedProjectTitle);
    }
    fetchProjects();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setLoading(false);
    navigate("/");
  };

  const handleLoginProject = (itemId, title) => {
    setProjectId(itemId);
    setProjectTitle(title);
    localStorage.setItem("projectId", itemId);
    localStorage.setItem("projectTitle", title);
    navigate(`/projects/${itemId}`);
  };
  
  let height2 = height1 * 0.85 ;

  return (
    <>
      <header className="Header-page">
        <div className="container">
          <div className="Header-container">
            <div className="Header-content">
              <div className="Header-logo">
                <img src={img1} alt="" />
              </div>
              <div className="Header-title">
                <h3>3DPanel</h3>
              </div>
            </div>
            <div className="Header-buttons">
              <CreateProject
                fetchProjects={fetchProjects}
                ToastsShow={setToastsShow}
                ToastsText={setToastsText}
                ToastsShowBack={setToastsShowBack}
              />
              <div className="Exit-btn">
                <button type="button" onClick={handleLogout}>
                  <Icon.BoxArrowLeft size={"25px"} color="#002D62" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="main">
        <div className="Projects-page">
          <div className="container">
            <div className="heading">
              <div className="BreadcrumbPage">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">Projects</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="Projects-content">
          <div className="container" style={{height : `${height2}px`}}>
            <div className="row Projects-card-group">
              {project.map((item, key) => {
                return (
                  <div
                    key={item.id}
                    class="card Card col-xl-3 col-lg-4 col-sm-6 col-12"
                  >
                    <div className="Card-container">
                      <div className="Card-image-container">
                        <div>
                          <img
                            src={dModeling}
                            alt=""
                            className="card-img-top Card-image"
                          />
                        </div>
                      </div>
                      <div className="card-body Card-body">
                        <h5 className="card-title Card-title">{item.title}</h5>
                        <p className="card-text Card-text">
                          {item.description}
                        </p>
                        <div className="Card-Buttons">
                          <button
                            type="button"
                            className="btn btn-primary Card-btn"
                            onClick={async () => {
                              handleLoginProject(item.id, item.title);
                              await setLoading(true);
                            }}
                          >
                            View Project
                          </button>
                        </div>
                        <RemoveBox
                          itemId={item.id}
                          projects={project}
                          funProjects={setProject}
                          setLoading={setLoading}
                          fetchProjects={fetchProjects}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
      <Toasts
        toastsShow={toastsShow}
        toastsText={toastsText}
        toastsShowBack={toastsShowBack}
      />
    </>
  );
}
