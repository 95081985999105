import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as Icon from "react-bootstrap-icons";
import "./ModalGuide.css";
import question from "../../assets/questions.png";
import Guide from '../../assets/Guide.jpg'

function GuideBox() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="primary"
        className="Guide-Bottom-Navbar-btn"
        onClick={handleShow}
        title="Guide"
      >
        <img src={question} alt="" className="Bottom-Navbar-img" />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        dir="rtl"
        className="ModalGui"
        size="xl"
      >
        <img src={Guide} alt="" />

        {/* <Modal.Header className="Gide-window-header">
          <Modal.Title className="Gide-window-title">راهنما</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Gide-window-body">
          <div className="row Gide-list">
            <section className="Gide-item col-lg-12 col-6">
              <div>
                <img src={click} alt="click" className="Gide-image" />
                <img src={rightClick} alt="rightClick" className="Gide-image" />
              </div>
              <p className="Gide-text-title">Pan</p>
              <p className="Gide-text">
                با نگه داشتن کلیک راست. و یا دکمه میانی موس می توانید به سمت
                راست و چپ حرکت کنید .
              </p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={leftClick} alt="click" className="Gide-image" />
              </div>
              <p className="Gide-text-title"> Rotate</p>
              <p className="Gide-text">
                با نگه داشتن کلیک چپ و حرکت می توانید دور مدل بچرخید .
              </p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={scroll} alt="rightClick" className="Gide-image" />
              </div>
              <p className="Gide-text-title">Zoom in-out</p>
              <p className="Gide-text">با حرکت غلطک موس بزرگنمایی کنید.</p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={arrows} alt="rightClick" className="Gide-image" />
              </div>
              <p className="Gide-text-title">Walkthrough</p>
              <p className="Gide-text">
                با کلیدهای جهتی می توانید در فضا راه بروید.
              </p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={undo} alt="rightClick" className="Gide-image" />
              </div>
              <p className="Gide-text-title">Reset</p>
              <p className="Gide-text">
                با کلید بازیابی میتوانید جایگاه دوربین را مجدد تنظیم کنید.
              </p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={Menu} alt="rightClick" className="Gide-image" />
              </div>
              <p className="Gide-text-title"> Properties</p>
              <p className="Gide-text">
                با انتخاب هر لایه و بازکردن پنجره می توانید ویژگی های هر بخش را
                مشاهده کنید.
              </p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={Menu} alt="rightClick" className="Gide-image" />
              </div>
              <p className="Gide-text-title">Up & Down</p>
              <p className="Gide-text">تغییر ارتفاع دوربین</p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={Menu} alt="rightClick" className="Gide-image" />
              </div>
              <p className="Gide-text-title">Run</p>
              <p className="Gide-text">سرعت بخشیدن</p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={Menu} alt="rightClick" className="Gide-image" />
              </div>
              <p className="Gide-text-title">Orbit</p>
              <p className="Gide-text">چرخش</p>
            </section>
            <section className="Gide-item col-lg-3 col-6">
              <div>
                <img src={fullSize} alt="" className="Gide-image" />
              </div>
              <p className="Gide-text-title">FullScreen</p>
              <p className="Gide-text"></p>
            </section>
          </div>
        </Modal.Body>
        <Modal.Footer className="Gide-window-footer">
          <div className="Share-buttons-right">
            <Button
              variant="light"
              className="Gide-Close-window-btn"
              onClick={handleClose}
            >
              بستن
            </Button>
          </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default GuideBox;
