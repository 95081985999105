import React, { useContext, useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import ShareProject from "../../../GelobalComponents/ModalShare/ModalShare";
import "./FilePage.css";
import img1 from "../../../assets/img1.png";
import back from "../../../assets/left.png";
import Canvas from "../../../GelobalComponents/Canvas/Canvas";
import LoadingModel from "../../../GelobalComponents/Loading/LoadingModel";
import Toasts from "../../../GelobalComponents/Toast/Toasts";
import { AppStateContext } from "../../../Context/contextState";
import { useNavigate, useParams } from "react-router-dom";

export default function FilePage() {
  const {
    loading,
    setLoading,
    projectTitle,
    setProjectTitle,
    fileDetail,
    setFileDetail,
  } = useContext(AppStateContext);

  const [toastsShow, setToastsShow] = useState(false);
  const [toastsShowBack, setToastsShowBack] = useState();
  const [toastsText, setToastsText] = useState();
  const navigate = useNavigate();

  const loadingPage = () => {
    setLoading(true);
  };

  const getDetailFile = async () => {
    loadingPage();
    const saveFileId = localStorage.getItem("fileId");
    const saveFilePath = localStorage.getItem("filePath");
    const saveFileTitle = localStorage.getItem("fileTitle");
    const savedProjectTitle = localStorage.getItem("projectTitle");
    const savedFileCode = localStorage.getItem("fileCode");
    if (saveFileId && saveFilePath) {
      await setFileDetail({
        fileId: saveFileId,
        filePath: saveFilePath,
        fileTitle: saveFileTitle,
        fileCode: savedFileCode,
      });

      setProjectTitle(savedProjectTitle);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
    setLoading(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getDetailFile();
  }, []);

  // console.log("fileDetail?.fileCode>>", fileDetail?.fileCode);

  return (
    <>
      <div className="ProjectPage">
        <div className="ProjectPage-heading">
          <div className="Header-container-canvas">
            <div className="Header-content">
              <div className="Backward">
                <button type="button" onClick={handleBack} title="Back">
                  <img src={back} alt="back" />
                </button>
              </div>
              <div className="Header-logo">
                <img src={img1} alt="" />
              </div>
              <div className="ProjectPage-title d-flex align-items-center">
                <h4>3D View</h4>
              </div>
            </div>
            <div className="Share">
              <ShareProject fileCodeParam={fileDetail?.fileCode} />
              <div className="Exit-btn">
                <button
                  type="button"
                  onClick={handleLogout}
                  style={{ marginBottom: "3px" }}
                  title="Exit"
                >
                  <Icon.BoxArrowLeft size={"25px"} color="#002D62" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ProjectPage-main" dir="ltr">
          <div className="BreadcrumbPage-file">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Projects</li>
                <li class="breadcrumb-item">{projectTitle}</li>
                <li class="breadcrumb-item">{fileDetail?.fileTitle}</li>
              </ol>
            </nav>
          </div>
          <Canvas
            FilePath={fileDetail?.filePath}
            ToastsShow={setToastsShow}
            ToastsShowBack={setToastsShowBack}
            ToastsText={setToastsText}
          />
        </div>
        {loading && <LoadingModel />}
        <Toasts
          toastsShow={toastsShow}
          toastsText={toastsText}
          toastsShowBack={toastsShowBack}
        />
      </div>
    </>
  );
}
