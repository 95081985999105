import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { AppStateContext } from "../../Context/contextState";
import axios from "axios";
import "./ModalCreateProject.css";

export default function CreateProject(props) {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("project");
  const [file, setFile] = useState();
  const { setLoading } = useContext(AppStateContext);
  const [uploaded, setUploaded] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit1 = async (event) => {
    if (file) {
      setUploaded(1);
    }

    try {
      const response = await fetch(
        "https://api.solidclouds.nl/api/projects",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ title, description }),
        }
      );
      const data = await response.json();
      if (response.ok && file) {
        const formdata = new FormData();
        formdata.append("title", file?.name);
        formdata.append("file", file);
        try {
          const response = await axios.post(
            `https://api.solidclouds.nl/api/files/${data.info.id}`,
            formdata,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploaded(percentage - 1);
              },
            }
          );
          if (response.status === 201) {
            console.log(response);
            setUploaded(0);
            handleClose();
            setLoading(true);
            setDisabledBtn(false);
            props.fetchProjects();
            props.ToastsShow(true);
            props.ToastsShowBack(true);
            props.ToastsText(
              "The file was uploaded successfully and a project was created."
            );
          }
        } catch (err) {
          console.error("Error:", err);
          setUploaded(0);
          setDisabledBtn(false);
          props.ToastsShow(true);
          props.ToastsShowBack(false);
          props.ToastsText("ERROR : File wasn't uploaded");
          props.fetchProjects();
        }

        
      } else {
        handleClose();
        props.fetchProjects();
        props.ToastsShow(true);
        props.ToastsShowBack(true);
        props.ToastsText("A project was created.");
      }
    } catch (err) {
      console.error("Error:", err);
      setUploaded(0);
      setDisabledBtn(false);
      props.ToastsShow(true);
      props.ToastsShowBack(false);
      props.ToastsText("ERROR : File wasn't uploaded");
      props.fetchProjects();
    } finally {
      setTimeout(() => {
        props.ToastsShow(false);
      }, 6000);
      setFile(null);
      setDisabledBtn(false);
      setDescription("project");
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDesChange = (event) => {
    setDescription(event.target.value);
  };

  return (
    <>
      <div className="Create-project" onClick={handleShow}>
        <div className="Create-project-btn">
          <span className="Create-project-btn-icon">+</span>
          Create Project
        </div>
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header className="Modal-Header">
          <Modal.Title>New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Modal-Body">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="Project-form-label">
                Project Name <span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                className="Project-form-btn"
                required
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="Project-form-label">
                Description
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Description"
                as="textarea"
                className="Project-form-btn"
                onChange={handleDesChange}
                defaultValue="project"
              />
            </Form.Group>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Label className="Project-form-label">
                Upload File <span>*</span>
                <span>
                  ( Just <span style={{ fontWeight: "400" }}>.ifc</span> )
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept=".ifc"
                className="Project-form-btn"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  const filebox = e.target.files[0];
                  if (!filebox.name.endsWith(".ifc")) {
                    alert("Please upload a valid .ifc file.");
                    return;
                  }
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="Modal-Footer">
          {uploaded > 0 && (
            <div
              className="progress ProgressBar"
              role="progressbar"
              aria-label="Example with label"
              aria-valuenow={uploaded}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div class="progress-bar" style={{ width: `${uploaded}%` }}>
                {uploaded}%
              </div>
            </div>
          )}
          <div>
            <Button
              variant="success"
              className="Save-project-btn"
              onClick={async () => {
                setDisabledBtn(true);
                await handleSubmit1();
              }}
              disabled={disabledBtn}
            >
              Save
            </Button>
            <Button
              variant="light"
              className="Cancel-project-btn"
              onClick={handleClose}
              disabled={disabledBtn}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
