import { useContext, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import CanvasPublish from "../../../GelobalComponents/Canvas/CanvasPubish";
import LoadingModel from "../../../GelobalComponents/Loading/LoadingModel";
import Toasts from "../../../GelobalComponents/Toast/Toasts";
import { AppStateContext } from "../../../Context/contextState";
import "./FilePage.css";

export default function ProjectPagePubish() {
  const { publishId } = useParams();
  const { loading } = useContext(AppStateContext);
  const [toastsShow, setToastsShow] = useState(false);
  const [toastsShowBack, setToastsShowBack] = useState();
  const [toastsText, setToastsText] = useState();

  return (
    <>
      <div className="ProjectPage">
        <div className="ProjectPage-main-publish" dir="ltr">
          <CanvasPublish
            fileCode1={publishId}
            ToastsShow={setToastsShow}
            ToastsShowBack={setToastsShowBack}
            ToastsText={setToastsText}
          />
        </div>
        {loading && <LoadingModel />}
        <Toasts
          toastsShow={toastsShow}
          toastsText={toastsText}
          toastsShowBack={toastsShowBack}
        />
      </div>
    </>
  );
}
