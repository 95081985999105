import './Loading.css'

export default function LoadingModel() {
  return (
    <>
      <div className="Loading-container">
        <span className="loader0"></span>
        <span className="loader0-title">Loading Model ...</span>
      </div>
    </>
  );
}