import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as Icon from "react-bootstrap-icons";
import { AppStateContext } from "../../Context/contextState";

function RemoveFileBox(props) {
  const [show, setShow] = useState(false);
  const { loading, setLoading } = useContext(AppStateContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRemove = async (fileId, fetchData) => {
    setShow(false);
    const savedProjectId = localStorage.getItem("projectId");

    try {
      const response = await fetch(
        `https://api.solidclouds.nl/api/files/${savedProjectId}/file/${props.itemId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        fetchData();
      } else {
        console.log("Error:", response.statusText);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        className="action-remove-btn"
        onClick={handleShow}
      >
        <Icon.Trash size={"20px"} color="#000" />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="Remove-window-header">
          <Modal.Title className="Remove-window-title">
            <Icon.ExclamationTriangleFill size={"25px"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Remove-window-body">
          Are you certain that you wish to remove this project?
        </Modal.Body>
        <Modal.Footer>
          <div className="Share-buttons-right">
            <Button
              variant="success"
              className="Yes-btn me-1"
              onClick={() => {
                handleRemove(props.fileId, props.fetchData);
                setLoading(true);
              }}
            >
              Yes
            </Button>
            <Button
              variant="light"
              className="Close-window-btn"
              onClick={handleClose}
            >
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RemoveFileBox;
